import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_page = _resolveComponent("page")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createVNode(_component_el_table, {
        data: _ctx.table,
        stripe: "",
        "empty-text": "没有记录",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            align: "center",
            type: "index",
            label: "序号",
            width: "50"
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            prop: "addReason",
            label: "列入经营异常名录原因"
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            prop: "addDate",
            label: "列入日期"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.toDate(scope.row.addDate,'YYYY-MM-DD')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            prop: "decisionOffice",
            label: "作出决定机关"
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            prop: "romoveReason",
            label: "移出经营异常名录原因"
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            prop: "removeDate",
            label: "移出日期"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.toDate(scope.row.removeDate,'YYYY-MM-DD')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            prop: "removeDecisionOffice",
            label: "移出决定机关"
          })
        ]),
        _: 1
      }, 8, ["data"]), [
        [_directive_loading, _ctx.loading]
      ])
    ]),
    _createVNode(_component_page, {
      currentPage: _ctx.pageIndex,
      pageSize: _ctx.pageSize,
      pages: _ctx.pageCount,
      onChangePage: _ctx.changePage
    }, null, 8, ["currentPage", "pageSize", "pages", "onChangePage"])
  ], 64))
}